import { createRouter, createWebHashHistory } from 'vue-router'
import { api } from '@/util/api'




export const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Index.vue')
  }
]
export const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(), // 使用 hash 模式
  routes
})


async function getRouterList() {
  try {
    const { data } = await api.get('/app/index/project/idList')
    data.data.forEach(item => {
      router.addRoute({
        path: `/${item}`,
        name: item,
        component: () => import('@/views/Index.vue')
      })
    })
  } catch (error) {
    console.log(error)
  }
}

export const initApp = async () => {
  await getRouterList() // 確保動態路由已加載完成
  router.replace() // 重新載入router讓動態路由生效
}
initApp()

// Middleware

router.beforeEach((to, from, next) => {
  next()
})
// const isAuthenticated = sessionStorage.getItem('token')
// if (to.matched.some(record => record.meta.requiresAuth)) {
//   if (!isAuthenticated) {
//     next({ name: 'login' })
//   } else {
//     next()
//   }
// } else {
//   next()
// }
