let platformConfig = {}
// 測試環境
const beforeOnlineConfig = {
  appId: 'dev-id',
  title: 'sdproject.xyz',
  baseURL: 'https://sdproject.xyz/gateway/',
  webSocketUrl: 'wss://jppay.store/gateway/websocket/connect',
  imgUrl: 'https://image.jppay.store/'
}
// 正式環境
const bitneerConfig = {
  appId: 'dev-id',
  title: 'sdproject.xyz',
  baseURL: 'https://sdproject.xyz/gateway/',
  webSocketUrl: 'wss://jppay.store/gateway/websocket/connect',
  imgUrl: 'https://image.jppay.store/'
}
switch (process.env.VUE_APP_TITLE) {
  case 'bitneer': // 正式
    platformConfig = bitneerConfig
    break
  case 'beforeOnline': // 測試
    platformConfig = beforeOnlineConfig
    break
  default:
    platformConfig = beforeOnlineConfig
}
export default platformConfig
